.sergiu1 {
  font-weight: 600;
  font-family: var(--font-public-sans);
}
.sergiu {
  margin: 0;
}
.painterDecorating {
  margin: 0;
  font-size: var(--font-size-3xl);
}
.home,
.sergiuPainterDecoratingContainer {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  color: inherit;
}
.sergiuPainterDecoratingContainer {
  flex: 1;
  line-height: 85.69%;
}
.home {
  letter-spacing: 0.07em;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 61.84px;
  flex-shrink: 0;
}
.headerLinksChild {
  position: relative;
  width: 0.5px;
  height: 54px;
}
.about {
  display: flex;
  align-items: center;
  width: 71.11px;
}
.about,
.services,
.workExamples {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  color: inherit;
  flex-shrink: 0;
}
.services {
  display: flex;
  align-items: center;
  width: 99.97px;
}
.workExamples {
  width: 180.35px;
}
.contact,
.headerLinks,
.workExamples {
  display: flex;
  align-items: center;
}
.contact {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  color: inherit;
  width: 98.94px;
  flex-shrink: 0;
}
.headerLinks {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-lgi);
  font-size: var(--font-size-xl);
  font-family: var(--font-roboto);
}
.frameIcon {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 37px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  cursor: pointer;
}
.header,
.headerContents {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.headerContents {
  margin: 0;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-63xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-public-sans);
}
.header {
  background-color: var(--color-burlywood-200);
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-169xl);
  align-items: flex-start;
}
.contact1 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 129.19%;
  text-transform: capitalize;
  font-weight: 500;
}
.headerAndHero,
.hero {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hero {
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-168xl);
  background-image: url(/public/hero2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.headerAndHero {
  overflow: hidden;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-45xl);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.sergiuPainterDecorating {
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 360px;
}
.sergiuPainterDecoratingContainer1 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
}
.mainDescription {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.mainInfoSection {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-169xl);
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}
.contactSectionIcon1 {
  position: relative;
  width: 41px;
  height: 41px;
  overflow: hidden;
  flex-shrink: 0;
}
.callUs {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 129.19%;
  text-transform: capitalize;
  font-weight: 300;
}
.contactRectangle1 {
  position: relative;
  background-color: var(--color-burlywood-100);
  width: 345px;
  height: 2px;
}
.div {
  position: relative;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
}
.contactSection1,
.contactSectionText1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.contactSection1 {
  align-self: stretch;
  gap: var(--gap-sm);
}
.iconLocation {
  position: relative;
  width: 41px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.contactMain {
  width: 345px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 87px;
  font-size: var(--font-size-xl);
}
.sergiuPainterDecorating3 {
  position: relative;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 705px;
  flex-shrink: 0;
}
.desktopContact,
.footerBottomBar {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
}
.footerBottomBar {
  align-self: stretch;
  background-color: var(--color-burlywood-100);
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-167xl);
  align-items: flex-end;
  font-size: var(--font-size-base);
}
.desktopContact {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 44px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 1200px) {
  .hero {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-91xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .headerLinks {
    display: none;
    gap: var(--gap-8xs);
  }
  .frameIcon {
    display: flex;
  }
  .header,
  .headerContents {
    height: auto;
    align-items: flex-start;
    justify-content: center;
  }
  .headerContents {
    gap: var(--gap-301xl);
  }
  .header {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .contact1 {
    font-size: var(--font-size-29xl);
  }
  .hero {
    padding-left: var(--padding-121xl);
    box-sizing: border-box;
  }
  .sergiuPainterDecorating,
  .sergiuPainterDecoratingContainer1 {
    font-size: var(--font-size-5xl);
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .sergiuPainterDecoratingContainer1 {
    font-size: var(--font-size-xs);
  }
  .mainInfoSection {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-41xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 570px) {
  .headerContents {
    gap: var(--gap-11xl);
  }
  .hero {
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-121xl);
    box-sizing: border-box;
  }
  .mainDescription {
    flex: unset;
    align-self: stretch;
  }
  .mainInfoSection {
    flex-direction: column;
  }
  .footerBottomBar {
    padding-left: var(--padding-3xs);
    box-sizing: border-box;
  }
}
