.sergiu1 {
  font-weight: 600;
  font-family: var(--font-public-sans);
}
.sergiu {
  margin: 0;
}
.painterDecorating {
  margin: 0;
  font-size: var(--font-size-3xl);
}
.home,
.sergiuPainterDecoratingContainer {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  color: inherit;
}
.sergiuPainterDecoratingContainer {
  flex: 1;
  line-height: 85.69%;
}
.home {
  letter-spacing: 0.07em;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 61.84px;
  flex-shrink: 0;
}
.headerLinksChild {
  position: relative;
  width: 0.5px;
  height: 54px;
}
.about {
  display: flex;
  align-items: center;
  width: 71.11px;
}
.about,
.services,
.workExamples {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  color: inherit;
  flex-shrink: 0;
}
.services {
  display: flex;
  align-items: center;
  width: 99.97px;
}
.workExamples {
  width: 180.35px;
}
.contact,
.headerLinks,
.workExamples {
  display: flex;
  align-items: center;
}
.contact {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  color: inherit;
  width: 98.94px;
  flex-shrink: 0;
}
.headerLinks {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-lgi);
  font-size: var(--font-size-xl);
  font-family: var(--font-roboto);
}
.frameIcon {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 37px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  cursor: pointer;
}
.header,
.headerContents {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.headerContents {
  margin: 0;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-63xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-public-sans);
}
.header {
  background-color: var(--color-burlywood-200);
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-169xl);
  align-items: flex-start;
}
.workExamples1 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 129.19%;
  text-transform: capitalize;
  font-weight: 500;
}
.headerAndHero,
.hero {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hero {
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-168xl);
  background-image: url(/public/hero1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.headerAndHero {
  overflow: hidden;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-45xl);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.clickOnThe,
.workExamplesRectangle {
  align-self: stretch;
  position: relative;
}
.clickOnThe {
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
}
.workExamplesRectangle {
  background-color: var(--color-burlywood-100);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  height: 4px;
  cursor: pointer;
}
.workExamplesTitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.workExamplesImage1Icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 361px;
  flex-shrink: 0;
  object-fit: cover;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.workExamplesRectangle1 {
  align-self: stretch;
  position: relative;
  background-color: var(--color-burlywood-100);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 11px;
  cursor: pointer;
  margin-top: -5px;
}
.workExamplesImageCarousel1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1);
  justify-content: flex-start;
}
.workExamplesContentsRow1,
.workExamplesMain {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.workExamplesContentsRow1 {
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-mini);
}
.workExamplesMain {
  flex-direction: column;
  padding: 0 185px;
  justify-content: flex-start;
  gap: 27px;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
.contactUs,
.pleaseShareYour {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 148.19%;
  text-transform: capitalize;
}
.contactUs {
  font-weight: 500;
}
.pleaseShareYour {
  font-size: var(--font-size-base);
  display: inline-block;
  width: 347px;
}
.contactUsInfo {
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.contactUs1 {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--font-roboto);
  color: var(--color-black);
  text-align: left;
}
.contactUsButton,
.contactUsInfoBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactUsButton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) var(--padding-31xl);
  background-color: var(--color-burlywood-100);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  flex-direction: row;
}
.contactUsButton:hover {
  background-color: var(--color-burlywood-300);
}
.contactUsInfoBox {
  align-self: stretch;
  background-color: var(--color-whitesmoke);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25) inset;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-102xl);
  gap: var(--gap-23xl);
}
.contactUsImageIcon {
  position: relative;
  width: 234px;
  height: 265px;
  object-fit: cover;
}
.contactUsSection {
  align-self: stretch;
  background-color: var(--color-burlywood-400);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-83xl) 0 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-121xl);
}
.sergiuPainterDecorating {
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 360px;
}
.sergiuPainterDecorating1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 705px;
}
.footerDescription {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.contact1 {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
}
.iconLocation {
  position: relative;
  width: 24px;
  height: 25px;
}
.cashelCoTipperary {
  position: relative;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.vectorIcon1,
.vectorIcon2 {
  position: relative;
  width: 25.5px;
  height: 25.5px;
}
.vectorIcon2 {
  width: 30px;
  height: 24px;
}
.mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.footerContactInfo,
.footerInfoSection {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footerContactInfo {
  flex-direction: column;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-3xs);
  font-size: var(--font-size-base);
}
.footerInfoSection {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-169xl);
  gap: var(--gap-22xl);
}
.sergiuPainterDecorating2 {
  position: relative;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 705px;
  flex-shrink: 0;
}
.footerBottomBar {
  align-self: stretch;
  background-color: var(--color-burlywood-100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-167xl);
  align-items: flex-end;
  justify-content: flex-start;
  font-size: var(--font-size-base);
}
.contactAndFooterSection,
.desktopWorkExamples,
.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer {
  align-self: stretch;
  gap: var(--gap-mini);
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
.contactAndFooterSection,
.desktopWorkExamples {
  overflow: hidden;
}
.contactAndFooterSection {
  align-self: stretch;
  gap: var(--gap-32xl);
}
.desktopWorkExamples {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  gap: var(--gap-63xl);
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 1200px) {
  .hero,
  .workExamplesMain {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-91xl);
    box-sizing: border-box;
  }
  .workExamplesMain {
    padding-left: var(--padding-101xl);
    padding-right: var(--padding-101xl);
  }
}
@media screen and (max-width: 960px) {
  .headerLinks {
    display: none;
    gap: var(--gap-8xs);
  }
  .frameIcon {
    display: flex;
  }
  .header,
  .headerContents {
    height: auto;
    align-items: flex-start;
    justify-content: center;
  }
  .headerContents {
    gap: var(--gap-301xl);
  }
  .header {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .workExamples1 {
    font-size: var(--font-size-29xl);
    text-align: center;
  }
  .hero,
  .workExamplesMain {
    padding-left: var(--padding-121xl);
    box-sizing: border-box;
  }
  .workExamplesMain {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
  }
  .contactUsImageIcon {
    display: none;
  }
  .contactUsSection {
    padding-right: 0;
    box-sizing: border-box;
  }
  .sergiuPainterDecorating,
  .sergiuPainterDecorating1 {
    font-size: var(--font-size-5xl);
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .sergiuPainterDecorating1 {
    font-size: var(--font-size-xs);
  }
  .footerInfoSection {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-41xl);
    box-sizing: border-box;
  }
  .contactAndFooterSection {
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 570px) {
  .headerContents {
    gap: var(--gap-11xl);
  }
  .workExamples1 {
    text-align: center;
  }
  .hero {
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-121xl);
    box-sizing: border-box;
  }
  .workExamplesImageCarousel1 {
    flex: unset;
    align-self: stretch;
  }
  .footerInfoSection,
  .workExamplesContentsRow1 {
    flex-direction: column;
  }
  .footerBottomBar {
    padding-left: var(--padding-3xs);
    box-sizing: border-box;
  }
}
