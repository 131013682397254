@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;
  --font-public-sans: "Public Sans";

  /* font sizes */
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-xs: 12px;
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-3xl: 22px;
  --font-size-45xl: 64px;
  --font-size-29xl: 48px;

  /* Colors */
  --color-white: #fff;
  --color-burlywood-100: #eaba7f;
  --color-burlywood-200: #eab676;
  --color-burlywood-300: #d2a671;
  --color-burlywood-400: rgba(234, 186, 127, 0.47);
  --color-black: #000;
  --color-whitesmoke: #f8f8f8;
  --color-dimgray: #505050;
  --color-gray: rgba(0, 0, 0, 0.69);

  /* Gaps */
  --gap-121xl: 140px;
  --gap-32xl: 51px;
  --gap-mini: 15px;
  --gap-22xl: 41px;
  --gap-3xs: 10px;
  --gap-11xl: 30px;
  --gap-23xl: 42px;
  --gap-8xs: 5px;
  --gap-base: 16px;
  --gap-101xl: 120px;
  --gap-sm: 14px;
  --gap-24xl: 43px;
  --gap-2xl: 21px;
  --gap-63xl: 82px;
  --gap-301xl: 320px;
  --gap-lgi: 19px;
  --gap-lg: 18px;
  --gap-69xl: 88px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-2xs: 11px;
  --padding-167xl: 186px;
  --padding-3xs: 10px;
  --padding-169xl: 188px;
  --padding-21xl: 40px;
  --padding-41xl: 60px;
  --padding-11xs: 2px;
  --padding-83xl: 102px;
  --padding-102xl: 121px;
  --padding-smi: 13px;
  --padding-31xl: 50px;
  --padding-9xs: 4px;
  --padding-101xl: 120px;
  --padding-81xl: 100px;
  --padding-8xs: 5px;
  --padding-97xl: 116px;
  --padding-lg: 18px;
  --padding-46xl: 65px;
  --padding-xl: 20px;
  --padding-168xl: 187px;
  --padding-91xl: 110px;
  --padding-121xl: 140px;
  --padding-170xl: 189px;

  /* Border radiuses */
  --br-11xs: 2px;
}
