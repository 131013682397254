.about,
.home {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  color: inherit;
}
.burgerMenu,
.burgerMenuLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.burgerMenuLinks {
  justify-content: center;
  gap: 48px;
}
.burgerMenu {
  position: relative;
  background-color: var(--color-burlywood-200);
  height: 1330px;
  overflow: hidden;
  padding: 27px 7px;
  box-sizing: border-box;
  justify-content: flex-start;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 420px) {
  .burgerMenu {
    display: flex;
  }
}
