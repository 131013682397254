.sergiu1 {
  font-weight: 600;
  font-family: var(--font-public-sans);
}
.sergiu {
  margin: 0;
}
.painterDecorating {
  margin: 0;
  font-size: var(--font-size-3xl);
}
.home,
.sergiuPainterDecoratingContainer {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  color: inherit;
}
.sergiuPainterDecoratingContainer {
  flex: 1;
  line-height: 85.69%;
}
.home {
  letter-spacing: 0.07em;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 61.84px;
  flex-shrink: 0;
}
.headerLinksChild {
  position: relative;
  width: 0.5px;
  height: 54px;
}
.about {
  display: flex;
  align-items: center;
  width: 71.11px;
}
.about,
.services,
.workExamples {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  color: inherit;
  flex-shrink: 0;
}
.services {
  display: flex;
  align-items: center;
  width: 99.97px;
}
.workExamples {
  width: 180.35px;
}
.contact,
.headerLinks,
.workExamples {
  display: flex;
  align-items: center;
}
.contact {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  color: inherit;
  width: 98.94px;
  flex-shrink: 0;
}
.headerLinks {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-lgi);
  font-size: var(--font-size-xl);
  font-family: var(--font-roboto);
}
.frameIcon {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 37px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  cursor: pointer;
}
.header,
.headerContents {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.headerContents {
  margin: 0;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-63xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-public-sans);
}
.header {
  background-color: var(--color-burlywood-200);
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-169xl);
  align-items: flex-start;
}
.professionalResidential {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.04em;
  line-height: 148.19%;
  text-transform: uppercase;
  font-weight: 600;
}
.heroRectangle {
  align-self: stretch;
  position: relative;
  background-color: var(--color-burlywood-200);
  height: 4px;
}
.heroTitle {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.learnMore,
.whetherItsYour {
  position: relative;
  line-height: 144.19%;
  text-transform: capitalize;
}
.whetherItsYour {
  align-self: stretch;
  font-weight: 100;
  font-size: 20px;
  letter-spacing: 0.02em;
}
.learnMore {
  letter-spacing: 0.03em;
  font-weight: 300;
}
.heroButton {
  cursor: pointer;
  text-decoration: none;
  background-color: var(--color-burlywood-200);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: var(--padding-8xs) var(--padding-3xs);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-xl);
  color: inherit;
}
.hero,
.heroContainer,
.heroInfobox {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.heroInfobox {
  justify-content: flex-start;
  gap: 39px;
  font-size: var(--font-size-base);
}
.hero,
.heroContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.heroContainer {
  height: 682px;
  flex-shrink: 0;
  padding: 0 280px 0 var(--padding-169xl);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-3xs);
  background-image: url(/public/herocontainer@3x.png);
}
.hero {
  padding: 0 433px 0 0;
  justify-content: flex-start;
  background-image: url(/public/hero@3x.png);
}
.top,
.workExamplesSection {
  align-self: stretch;
  display: flex;
  align-items: center;
}
.top {
  height: 756px;
  flex-direction: column;
  justify-content: flex-start;
}
.workExamplesSection {
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-mini);
}
.whyChooseUs {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.04em;
  line-height: 148.19%;
  text-transform: uppercase;
  font-weight: 500;
}
.whyChooseUsTitleSectionChild {
  align-self: stretch;
  position: relative;
  background-color: var(--color-burlywood-200);
  height: 1px;
}
.whyChooseUsTitleSection {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-24xl);
}
.qualityicon {
  position: relative;
  width: 154px;
  height: 154px;
  cursor: pointer;
}
.highWorkQuality {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}
.wcuInfoBox1,
.wcuInfoBox2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-2xl);
}
.moneyicon {
  position: relative;
  width: 154px;
  height: 154px;
  cursor: pointer;
}
.competitivePrices {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}
.wcuInfoBox3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.whyChooseUsInfoBoxes,
.whyChooseUsSection {
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.whyChooseUsInfoBoxes {
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  font-size: var(--font-size-xl);
}
.whyChooseUsSection {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-24xl);
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
.vectorIcon1 {
  position: relative;
  width: 12.66px;
  height: 21.13px;
}
.arrowleft,
.arrows,
.workExampleCarousel1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.arrowleft {
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) var(--padding-3xs);
  background-color: var(--color-gray);
  width: 33.5px;
  box-sizing: border-box;
  justify-content: center;
}
.arrows,
.workExampleCarousel1 {
  align-self: stretch;
  justify-content: space-between;
}
.workExampleCarousel1 {
  border-radius: var(--br-11xs);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-97xl) 0;
  justify-content: flex-start;
  background-image: url(/public/workexamplecarousel1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.workExampleRectangle1 {
  position: relative;
  background-color: var(--color-burlywood-100);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 267.75px;
  height: 10px;
  margin-top: -5px;
}
.workExampleBox1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.arrowleft1,
.arrowright1 {
  cursor: pointer;
  border: 0;
  padding: 17px var(--padding-3xs);
  background-color: var(--color-gray);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.arrowright1 {
  padding: var(--padding-lg) var(--padding-3xs);
}
.workExampleCarousel2 {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 117px 0;
  align-items: center;
  justify-content: flex-start;
  background-image: url(/public/workexamplecarousel2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.workExampleBox2 {
  flex: 1;
  height: 296px;
}
.workExampleBox2,
.workExampleBox3,
.workExampleCarousel3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.workExampleCarousel3 {
  align-self: stretch;
  border-radius: var(--br-11xs);
  overflow: hidden;
  padding: var(--padding-97xl) 0;
  background-image: url(/public/workexamplecarousel3@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.workExampleBox3 {
  flex: 1;
}
.workExampleCarousels {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sm);
}
.headerAndMain,
.main,
.workExamples2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.workExamples2 {
  justify-content: center;
  gap: 9px;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
.headerAndMain,
.main {
  overflow: hidden;
  justify-content: flex-start;
}
.main {
  padding: 0 198px;
  gap: var(--gap-101xl);
}
.headerAndMain {
  gap: 118px;
}
.promptProfessional {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 159.19%;
  text-transform: capitalize;
  font-weight: 300;
}
.commentsRectangle {
  position: relative;
  background-color: var(--color-burlywood-200);
  width: 884.17px;
  height: 1px;
}
.commentChangeButtonLeftChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--color-white);
  border: 1px solid var(--color-dimgray);
  box-sizing: border-box;
  width: 41px;
  height: 41px;
}
.fill35Icon {
  position: absolute;
  height: 53.25%;
  width: 30.71%;
  top: 21.95%;
  right: 38.93%;
  bottom: 24.79%;
  left: 30.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.commentChangeButtonLeft {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 41px;
  height: 41px;
}
.fill35Icon1 {
  position: absolute;
  height: 55.26%;
  width: 30.71%;
  top: 21.95%;
  right: 31.12%;
  bottom: 22.79%;
  left: 38.17%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.commentButtonsSection,
.commentChangeButtons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.commentChangeButtons {
  flex-direction: row;
  gap: 8px;
}
.commentButtonsSection {
  overflow: hidden;
  flex-direction: column;
}
.cindyVanoosten {
  flex: 1;
  position: relative;
  letter-spacing: 0.04em;
  line-height: 148.19%;
  text-transform: capitalize;
}
.comments,
.underCommentSection {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.underCommentSection {
  overflow: hidden;
  flex-direction: row;
  gap: var(--gap-base);
}
.comments {
  flex-direction: column;
  padding: 0 var(--padding-169xl);
  gap: var(--gap-8xs);
}
.contactUs,
.pleaseShareYour {
  position: relative;
  letter-spacing: 0.04em;
  line-height: 148.19%;
  text-transform: capitalize;
}
.contactUs {
  font-weight: 500;
}
.pleaseShareYour {
  font-size: var(--font-size-base);
  display: inline-block;
  width: 347px;
}
.contactUsInfo {
  display: flex;
  flex-direction: column;
  padding: var(--padding-9xs) 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.contactUs1 {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--font-roboto);
  color: var(--color-black);
  text-align: left;
}
.contactUsButton,
.contactUsInfoBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactUsButton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-smi) var(--padding-31xl);
  background-color: var(--color-burlywood-100);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  flex-direction: row;
}
.contactUsButton:hover {
  background-color: var(--color-burlywood-300);
}
.contactUsInfoBox {
  align-self: stretch;
  background-color: var(--color-whitesmoke);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25) inset;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-102xl);
  gap: var(--gap-23xl);
}
.contactUsImageIcon {
  position: relative;
  width: 234px;
  height: 265px;
  object-fit: cover;
}
.contactUsSection {
  align-self: stretch;
  background-color: var(--color-burlywood-400);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-83xl) 0 0;
  align-items: center;
  justify-content: center;
  gap: var(--gap-121xl);
}
.sergiuPainterDecorating {
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 360px;
}
.sergiuPainterDecorating1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 705px;
}
.footerDescription {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.contact1 {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
}
.iconLocation {
  position: relative;
  width: 24px;
  height: 25px;
}
.cashelCoTipperary {
  position: relative;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.vectorIcon7,
.vectorIcon8 {
  position: relative;
  width: 25.5px;
  height: 25.5px;
}
.vectorIcon8 {
  width: 30px;
  height: 24px;
}
.mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.footerContactInfo,
.footerInfoSection {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.footerContactInfo {
  flex-direction: column;
  padding: 0 var(--padding-11xs);
  gap: var(--gap-3xs);
  font-size: var(--font-size-base);
}
.footerInfoSection {
  align-self: stretch;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-169xl);
  gap: var(--gap-22xl);
}
.sergiuPainterDecorating2 {
  position: relative;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 705px;
  flex-shrink: 0;
}
.contactAndFooterSection,
.footer,
.footerBottomBar {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.footerBottomBar {
  background-color: var(--color-burlywood-100);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-167xl);
  align-items: flex-end;
  font-size: var(--font-size-base);
}
.contactAndFooterSection,
.footer {
  flex-direction: column;
  align-items: flex-start;
}
.footer {
  gap: var(--gap-mini);
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
.contactAndFooterSection {
  overflow: hidden;
  gap: var(--gap-32xl);
  text-align: center;
  font-size: var(--font-size-5xl);
}
.bottomSection,
.desktopHome {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomSection {
  align-self: stretch;
  justify-content: center;
  gap: 81px;
  font-size: var(--font-size-base);
}
.desktopHome {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  justify-content: flex-start;
  gap: 186px;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 1200px) {
  .heroContainer {
    padding-left: var(--padding-101xl);
    padding-right: 220px;
  }
  .hero,
  .heroContainer,
  .main {
    box-sizing: border-box;
  }
  .hero {
    padding-right: 250px;
  }
  .main {
    padding-left: var(--padding-101xl);
    padding-right: var(--padding-101xl);
  }
  .headerAndMain {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .headerLinks {
    display: none;
    gap: var(--gap-8xs);
  }
  .frameIcon {
    display: flex;
  }
  .header,
  .headerContents {
    height: auto;
    align-items: flex-start;
    justify-content: center;
  }
  .headerContents {
    gap: var(--gap-301xl);
  }
  .header {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .heroContainer {
    padding-left: var(--padding-41xl);
    padding-right: 210px;
    box-sizing: border-box;
  }
  .hero {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .workExamplesSection {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .workExampleBox1,
  .workExampleBox2 {
    flex: unset;
    align-self: stretch;
  }
  .workExampleCarousel3 {
    flex: 1;
  }
  .workExampleBox3 {
    flex: unset;
    align-items: center;
    justify-content: center;
    align-self: stretch;
  }
  .workExampleCarousels {
    flex-direction: column;
  }
  .comments,
  .main {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
  .comments {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
  }
  .contactUsImageIcon {
    display: none;
  }
  .contactUsSection {
    padding-right: 0;
    box-sizing: border-box;
  }
  .sergiuPainterDecorating,
  .sergiuPainterDecorating1 {
    font-size: var(--font-size-5xl);
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .sergiuPainterDecorating1 {
    font-size: var(--font-size-xs);
  }
  .footerInfoSection {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-41xl);
    box-sizing: border-box;
  }
  .contactAndFooterSection {
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 650px) {
  .heroContainer {
  height: auto;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xl) var(--padding-101xl) var(--padding-xl)
    var(--padding-xl);
  box-sizing: border-box;
}
.hero {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  box-sizing: border-box;
}
}
@media screen and (max-width: 570px) {
  .headerContents {
    gap: var(--gap-11xl);
  }
  .professionalResidential {
    font-size: var(--font-size-5xl);
  }
  .whetherItsYour {
    font-size: var(--font-size-xs);
  }
  .heroContainer {
    height: auto;
    align-items: flex-start;
    justify-content: center;
    padding: var(--padding-xl) var(--padding-101xl) var(--padding-xl)
      var(--padding-xl);
    box-sizing: border-box;
  }
  .hero {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .workExamplesSection {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .wcuInfoBox1,
  .wcuInfoBox2,
  .wcuInfoBox3 {
    flex: unset;
    align-self: stretch;
  }
  .wcuInfoBox2,
  .wcuInfoBox3 {
    height: auto;
  }
  .whyChooseUsInfoBoxes {
    height: auto;
    flex-direction: column;
  }
  .whyChooseUsSection {
    display: flex;
    flex: 1;
  }
  .workExampleBox1,
  .workExampleBox2,
  .workExampleBox3 {
    flex: unset;
    align-self: stretch;
  }
  .workExampleCarousels {
    height: auto;
    flex-direction: column;
  }
  .workExamples2 {
    display: flex;
  }
  .main {
    gap: 80px;
    padding-left: var(--padding-8xs);
    padding-right: var(--padding-8xs);
    box-sizing: border-box;
  }
  .headerAndMain {
    height: auto;
    gap: 20px;
  }
  .comments {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
  .footerInfoSection {
    flex-direction: column;
  }
  .footerBottomBar {
    padding-left: var(--padding-3xs);
    box-sizing: border-box;
  }
  .desktopHome {
    height: auto;
    gap: var(--gap-121xl);
  }
}
