.workExamplesImage1Icon {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-11xs);
  max-width: 100%;
  overflow: hidden;
  height: 291px;
  flex-shrink: 0;
  object-fit: cover;
}
.commercial {
  position: relative;
  font-size: var(--font-size-xl);
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--font-roboto);
  color: var(--color-black);
  text-align: left;
  z-index: 0;
}
.workExamplesButton1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-3xs) var(--padding-46xl);
  background-color: var(--color-burlywood-100);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.workExamplesBox1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (max-width: 960px) {
  .workExamplesBox1,
  .workExamplesButton1 {
    align-items: center;
    justify-content: flex-start;
  }
  .workExamplesBox1 {
    justify-content: flex-end;
    flex: unset;
    align-self: stretch;
  }
}
@media screen and (max-width: 420px) {
  .workExamplesBox1 {
    align-self: stretch;
    width: auto;
    height: auto;
    flex: unset;
  }
}
